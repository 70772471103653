<template>
  <main >
    <div class="header">
      <h1 class="heading" v-if="$route.params.title">
        {{ $route.params.title }}: {{ objects.item.name }}
      </h1>
      <template v-else>
        <h1 class="heading" v-if="objects.status === 'create'">
          {{ $t('objects["Новый объект"]') }}
        </h1>
        <h1 class="heading" v-else>
          {{ $t('objects["Редактировать объект"]') }}
        </h1>
      </template>
    </div>
    <div class="object-success">
      <div class="object-actions">
        <div class="object-actions__list">
          <div class="object-actions__item">
            <h3 class="object-actions__title">
              {{ $t('objects["Лендинг"]') }}
            </h3>
            <div class="object-actions__img" @click="routLand()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="119"
                viewBox="0 0 120 119"
              >
                <g>
                  <g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M112.317 114.078H89.84v-7.066c0-.432.351-.784.784-.784h20.908c.432 0 .784.352.784.784zM84.537 85.93a7.307 7.307 0 0 1-1.689-4.666V76.17a3.665 3.665 0 0 1 3.66-3.66h.977v6.026a2.344 2.344 0 0 0 4.687 0V51.124c0-2.855 4.3-2.834 4.3 0v14.461a5.276 5.276 0 0 0 5.27 5.27h8.647a4.929 4.929 0 0 1 4.923 4.923v9.487c0 5.102-3.448 7.61-3.71 7.979-3.528 3.528-2.82 7.73-2.912 8.297H93.152c-.107-.824.615-4.524-2.28-8zm-11.15-28.798a4.75 4.75 0 0 1-4.745 4.745H9.432a4.75 4.75 0 0 1-4.745-4.745v-38.09H7.97a2.344 2.344 0 0 0 0-4.687H4.687c.009-5.157-.018-4.984.025-5.407a4.752 4.752 0 0 1 4.72-4.26h59.21a4.75 4.75 0 0 1 4.745 4.744v4.923H26.719a2.344 2.344 0 0 0 0 4.687h46.668v2.405h-.815c-4.735 0-8.588 3.852-8.588 8.587V47.06c0 4.735 3.853 8.588 8.588 8.588h.815zm32.366-27.098V47.06c0 2.15-1.75 3.9-3.9 3.9h-.696c-.085-3.496-2.83-6.418-6.374-6.653-3.878-.266-7.205 2.776-7.296 6.653H72.572c-2.15 0-3.9-1.75-3.9-3.9V30.034c0-2.15 1.75-3.9 3.9-3.9h29.28c2.15 0 3.9 1.75 3.9 3.9zm9.856 65.833A14.896 14.896 0 0 0 120 85.265v-9.487c0-5.3-4.312-9.611-9.61-9.611h-8.648a.583.583 0 0 1-.583-.582v-9.938h.693c4.735 0 8.588-3.852 8.588-8.588V30.035c0-4.736-3.853-8.588-8.588-8.588H78.074V9.433C78.074 4.27 73.876 0 68.642 0H9.432C4.233 0 0 4.23 0 9.432v47.7c0 5.201 4.232 9.433 9.433 9.433h59.21c5.2 0 9.431-4.232 9.431-9.433v-1.485h9.41v12.175h-.975c-4.603 0-8.348 3.745-8.348 8.348.31 3.336-1.122 8.08 2.773 12.76l6.336 7.61c1.674 2.011 1.064 4.053 1.194 5.447a5.479 5.479 0 0 0-3.31 5.026v9.409a2.344 2.344 0 0 0 2.343 2.344h27.164a2.344 2.344 0 0 0 2.343-2.344v-9.41a5.48 5.48 0 0 0-3.626-5.15v-1.588c0-2.746 1.949-4.027 2.23-4.407z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M59.893 35.043h-3.575V31.47a2.344 2.344 0 0 0-4.687 0v3.574h-3.575a2.344 2.344 0 0 0 0 4.688h3.575v3.575a2.344 2.344 0 0 0 4.687 0V39.73h3.575a2.344 2.344 0 0 0 0-4.688z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M30.019 35.043h-3.575V31.47a2.344 2.344 0 0 0-4.688 0v3.574h-3.575a2.344 2.344 0 0 0 0 4.688h3.575v3.575a2.344 2.344 0 0 0 4.688 0V39.73h3.575a2.344 2.344 0 0 0 0-4.688z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M63.828 11.783h3.75a2.344 2.344 0 0 0 0-4.687h-3.75a2.344 2.344 0 0 0 0 4.687z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M19.693 16.699a2.344 2.344 0 0 0-2.343-2.344h-.006a2.341 2.341 0 0 0-2.341 2.344 2.346 2.346 0 0 0 4.69 0z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M82.728 40.89h2.14v2.14a2.344 2.344 0 0 0 4.688 0v-2.14h2.14a2.344 2.344 0 0 0 0-4.687h-2.14v-2.14a2.344 2.344 0 0 0-4.687 0v2.14h-2.14a2.344 2.344 0 0 0 0 4.688z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="object-actions__actions">
              <v-btn
                class="object-actions__landing btn btn_primary"
                color="primary"
                small
                outlined
                dense
                block
                :to="{
                  name: 'Constructor',
                  params: { id: objects.item.id, lang: $route.params.lang },
                }"
              >
                <v-icon color="primary">mdi-pencil</v-icon>
                {{ $t('objects["Редактировать лендинг"]') }}
              </v-btn>
            </div>
          </div>
          <div class="object-actions__item">
            <h3 class="object-actions__title">
              {{ $t('objects["Стикеры"]') }}
            </h3>
            <div class="object-actions__img" @click="routPdf()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="89"
                height="120"
                viewBox="0 0 89 120"
              >
                <g>
                  <g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M79.967 103.94H19.322a3.978 3.978 0 0 1-3.973-3.972V89.063a2.344 2.344 0 1 0-4.688 0v10.905c0 4.776 3.886 8.66 8.661 8.66h49.27v3.67a3.018 3.018 0 0 1-3.014 3.015H11.773c-3.907 0-7.086-3.18-7.086-7.086V20.576a3.018 3.018 0 0 1 3.014-3.016h2.96v52.753a2.344 2.344 0 0 0 4.688 0V8.66a3.978 3.978 0 0 1 3.973-3.972h40.575v17.71a6.375 6.375 0 0 0 6.367 6.367h17.675v71.203a3.978 3.978 0 0 1-3.972 3.973zm2.27-79.862H66.264c-.927 0-1.68-.754-1.68-1.68V5.35c.617.319 1.18.746 1.657 1.266l14.882 16.245zm3.659-2.946a.375.375 0 0 1-.026-.028l-.037-.04-.775-.845L69.698 3.45A10.559 10.559 0 0 0 63.242.09L63.234.09l-.1-.012A10.551 10.551 0 0 0 61.858 0H19.322c-4.775 0-8.66 3.885-8.66 8.66v4.212h-2.96C3.454 12.872 0 16.328 0 20.576v87.651C0 114.72 5.28 120 11.773 120h53.805c4.246 0 7.702-3.455 7.702-7.701v-3.67h6.686c4.775 0 8.66-3.886 8.66-8.66V28.246c0-2.637-.969-5.16-2.73-7.115z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M27.903 33.396h25.058a2.344 2.344 0 0 0 0-4.688H27.903a2.344 2.344 0 1 0 0 4.688z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M64.648 48.095H27.904a2.344 2.344 0 1 0 0 4.687h36.744a2.344 2.344 0 1 0 0-4.687z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M64.648 67.48H27.904a2.344 2.344 0 1 0 0 4.688h36.744a2.344 2.344 0 1 0 0-4.687z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M64.648 86.866H27.904a2.344 2.344 0 1 0 0 4.688h36.744a2.344 2.344 0 1 0 0-4.688z"
                      />
                    </g>
                    <g>
                      <path
                        fill="#007db5"
                        d="M13.006 77.347a2.347 2.347 0 0 0-2.344 2.346 2.344 2.344 0 1 0 4.688 0v-.005a2.342 2.342 0 0 0-2.344-2.341z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="object-actions__actions">
              <v-btn
                class="object-actions__btn"
                color="primary"
                small
                outlined
                dense
                @click="downloadPdf"
              >
                <v-icon color="primary">mdi-download</v-icon>
                {{ $t('objects["Скачать PDF"]') }}
              </v-btn>
              <v-btn
                class="object-actions__btn"
                color="primary"
                depressed
                small
                outlined
                dense
                :to="{
                  name: 'StickersCreate',
                  params: { lang: $route.params.lang },
                }"
              >
                <v-icon color="primary">mdi-file-outline</v-icon>
                {{ $t('objects["Заказать"]') }}
              </v-btn>
            </div>
          </div>
          <div class="object-actions__item">
            <h3 class="object-actions__title">
              Emails
            </h3>
            <div class="object-actions__img">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 474 474" style="enable-background:new 0 0 474 474;" xml:space="preserve">
<g>
	<path
      fill="#007db5" d="M437.5,59.3h-401C16.4,59.3,0,75.7,0,95.8v282.4c0,20.1,16.4,36.5,36.5,36.5h401c20.1,0,36.5-16.4,36.5-36.5V95.8
		C474,75.7,457.6,59.3,437.5,59.3z M432.2,86.3L239.5,251.1L46.8,86.3H432.2z M447,378.2c0,5.2-4.3,9.5-9.5,9.5h-401
		c-5.2,0-9.5-4.3-9.5-9.5V104.9l203.7,174.2c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.3,0.2,0.5,0.4,0.8,0.5
		c0.1,0.1,0.2,0.1,0.3,0.2c0.4,0.2,0.8,0.4,1.2,0.6c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.3,1,0.4c0.1,0,0.3,0.1,0.4,0.1
		c0.3,0.1,0.6,0.2,0.9,0.2c0.1,0,0.3,0.1,0.4,0.1c0.3,0.1,0.7,0.1,1,0.2c0.1,0,0.2,0,0.3,0c0.4,0,0.9,0.1,1.3,0.1l0,0l0,0
		c0.4,0,0.9,0,1.3-0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,1-0.2c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.6-0.2,0.9-0.2c0.1,0,0.3-0.1,0.4-0.1
		c0.3-0.1,0.6-0.2,1-0.4c0.1,0,0.2-0.1,0.3-0.1c0.4-0.2,0.8-0.4,1.2-0.6c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.2,0.5-0.3,0.8-0.5
		c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.3L447,109.2V378.2z"/>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
</svg>

            </div>
            <div class="object-actions__actions">
              <v-btn
                  class="object-actions__landing btn btn_primary"
                  color="primary"
                  depressed
                  block
                  small
                  outlined
                  dense
                  :to="{
                  name: 'Constructor',
                  params: { id: objects.item.id, lang: $route.params.lang,tab:'email' },
                }"
              >
                <v-icon color="primary">mdi-pencil</v-icon>
                Edit emails
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <v-btn
        class="object-success__btn btn btn_primary"
        color="lightgrey"
        small
        outlined
        dense
        @click="goToObjects"
      >
        {{ $t('objects["В мои объекты"]') }}
      </v-btn>
    </div>
    <PopupEmail
      ref="PopupEmail"
      :title="$t('popup.sticker_email', { email: 'me@bs.hr' })"
    />
  </main>
</template>

<script>
// import store from '@/store'
import { mapState } from "vuex";
import PopupEmail from "@/components/Popup/PopupEmail.vue";

export default {
  name: "ObjectsSuccess",
  components: {
    PopupEmail,
  },
  computed: {
    ...mapState(["objects"]),
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
      async routEmail(){
      await this.$router.push({
        name: 'Constructor',
        params: { id: this.objects.item.id, lang: this.$route.params.lang,tab:'email' },
      });
    },
      async routPdf(){
      await this.$router.push({
            name: 'StickersCreate',
            params: { lang: this.$route.params.lang },
          });
    },
      async routLand(){
      await this.$router.push({
            name: 'Constructor',
            params: { id: this.objects.item.id, lang: this.$route.params.lang },
          });
    },
      async  goToObjects() {
      await this.$router.push({
        name: "My property",
        params: { lang: this.$route.params.lang },
      });
    },
    downloadPdf() {
      this.$refs.PopupEmail.open();
    },
  },
};
</script>

<style lang="scss">
.object-success {
  @include box;
  padding: 40px;
}

.object-success__heading {
  margin-bottom: 30px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}

.object-success__btn {
  display: block;
  margin: 30px auto 0;
  max-width: 220px;
  width: 100%;
}

.object-actions {
  width: 100%;
  margin: 0 auto;
}

.object-actions__list {
  @include row;
}

.object-actions__item {
  @include col(4);
  @include box;
  padding: 20px;
}

.object-actions__title {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.object-actions__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin: 0 auto 50px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
}

.object-actions__actions {
  display: flex;
  margin: 0 -5px;
}

.object-actions__btn {
  margin: 0 5px;
  width: calc(50% - 10px);
}

.object-actions__landing {
  flex: initial;
  width: calc(100% - 10px);
  min-width: initial !important;
  margin: 0 5px;
}
</style>
